import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import s7 from '../../../common/assets/image/interior/icons/s7.png';
import s1 from '../../../common/assets/image/interior/icons/s1.png';

import s12 from '../../../common/assets/image/interior/icons/s12.png';
import s2 from '../../../common/assets/image/interior/icons/s2.png';

import s9 from '../../../common/assets/image/interior/icons/s9.png';
import s3 from '../../../common/assets/image/interior/icons/s3.png';

import s10 from '../../../common/assets/image/interior/icons/s10.png';
import s4 from '../../../common/assets/image/interior/icons/s4.png';

import s11 from '../../../common/assets/image/interior/icons/s11.png';
import s5 from '../../../common/assets/image/interior/icons/s5.png';

import s8 from '../../../common/assets/image/interior/icons/s8.png';
import s6 from '../../../common/assets/image/interior/icons/s6.png';

import Fade from 'react-reveal/Fade';


// import mensHairReplace from '../../../common/assets/image/interior/services/mens-hair-replacement.jpg';
// import customizedWigs from '../../../common/assets/image/interior/services/customized-wigs.jpg';
// import hairBonding from '../../../common/assets/image/interior/services/hair-bonding.jpg';
// import hairWeaving from '../../../common/assets/image/interior/services/hair-weaving.jpg';
// import hairClipping from '../../../common/assets/image/interior/services/hair-clipping.jpg';
// import womenVolumizer from '../../../common/assets/image/interior/services/women-volumizer.jpg';
import SectionWrapper, {
  Container,
} from './whyRestoration.style';
import { SectionHeader } from '../global.style';
import Heading from 'common/components/Heading';

const WhyRestoration = () => {

	useEffect(() => {
		import("bootstrap/dist/js/bootstrap.bundle.min.js").then(() => {
		  // Bootstrap JavaScript is loaded and can be used here
		  // Initialize Bootstrap components or perform other necessary operations
		});
	  }, []);
  
  return (
    <SectionWrapper id="whyRestoration">
		<Fade bottom>
		<Container class="myContainer">
		<Fade left>
        <SectionHeader className='' style={{textAlign: 'left', paddingBottom: '25px'}}>
          <Heading as="h5" content="Our Services" />
          <Heading content="Hair Wig For Men And Women in Delhi" />
        </SectionHeader>
		</Fade>
		</Container>
      </Fade>
	  <Fade bottom>
      <Container class="myContainer">
        <div className='row'>
      		<div className="col-md-3 mb-35">
					<div className="services__area-button">
      					<ul className="nav nav-pills mypx-3">
							<li className="nav-item">
								<button className="nav-item-button active" data-bs-toggle="pill" data-bs-target="#haircut">
									<img className="nav-item-button-icon" src={s10} alt="" />
									<img className="nav-item-button-icon2 white" src={s4} alt="" />
                    				Men’s Hair
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button data-bs-toggle="pill" data-bs-target="#washing" className="nav-item-button">
									<img className="nav-item-button-icon" src={s12} alt="" />
									<img className="nav-item-button-icon2 white" src={s2} alt="" />
									Women Hair
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button data-bs-toggle="pill" data-bs-target="#color" className="nav-item-button">
									<img className="nav-item-button-icon" src={s7} alt="" />
									<img className="nav-item-button-icon2 white" src={s1} alt="" />
									Customized Wigs
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button className="nav-item-button" data-bs-toggle="pill" data-bs-target="#facial">
									<img className="nav-item-button-icon" src={s9} alt="" />
									<img className="nav-item-button-icon2 white" src={s3} alt="" />
									Hair Bonding
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button data-bs-toggle="pill" data-bs-target="#shave" className="nav-item-button">
									<img className="nav-item-button-icon" src={s8} alt="" />
									<img className="nav-item-button-icon2 white" src={s6} alt="" />
									Hair Weaving
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button data-bs-toggle="pill" data-bs-target="#massage" className="nav-item-button">
									<img className="nav-item-button-icon" src={s11} alt="" />
									<img className="nav-item-button-icon2 white" src={s5} alt="" />
									Hair Clipping
								</button>
							</li>
						</ul>
            </div>
				</div>

            <div className="col-md-9">					
					<div className="tab-content">
						<div className="tab-pane fade active show" id="haircut">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4">
										<img className="img__full" src="/home/image 2.png" alt="" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Men’s Hair Replacement System</h3>
											<p>With a 0.3 mm base and 100% natural human hair, you look stunning. Because the hair bonding procedure ensures that the hair system stays in place at all times, you can use any hair colour, gel them up, and swim with complete confidence. By shampooing them with your preferred high-quality conditioner and blow-drying them, you may achieve the look you've always desired—and it's unconditional. </p>
											<Link to="/hair-replacement-for-men.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
											Know More
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="washing">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4">
										<img className="img__full" src="/home/women-hair.jpg" alt="Volumizer Hair Additions for Women" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Volumizer Hair Additions for Women</h3>
											<p>
											Yes, you heard it right—our solutions aren’t just for men; women can also enjoy their favorite hairstyles without compromise. With thicker, longer hair and the volume they’ve always desired, women can enhance their beauty with ease.</p>
											<p>We offer an extensive range of products designed specifically for women. If you’re feeling stressed or worried about hair loss and have given up hope of regaining natural growth, we’re here to assure you that a new, confident version of yourself is just one appointment away. </p>
											<Link to="/volumizer-hair-additions-for-women.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
											Know More
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="color">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4">
										<img className="img__full" src="/home/customized-wigs.jpg" alt="Customized Wigs" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Customized Wigs</h3>
											<p>Now, you might be wondering—how can a wig be customized? Let us clarify—we’re not talking about the outdated wigs from the 1990s. Our wigs are crafted based on precise scalp measurements taken by our hair experts, along with a sample of your natural hair to ensure a perfect texture match.</p>
											<p>From there, you select the product of your choice, and our skilled artisans at RADIANCE will create the look you desire, giving you unmatched confidence—guaranteed 100%. </p>
											<Link to="/customized-wigs-for-women.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
											Know More
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="facial">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4">
										<img className="img__full" src="/home/Hair-Bonding.jpg" alt="Hair Bonding" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Hair Bonding</h3>
											<p>When we mention hair bonding, it means no surgery, no pain, and no invasive tools. It’s simply your chosen product paired with a specialized adhesive designed for this purpose.</p>
											<p>At Radiance, we provide all the latest innovations in the hair care industry, coupled with guaranteed discounts on the original prices.</p>
											<Link to="/hair-bonding-for-women.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
											Know More
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="shave">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4" >
										<img className="img__full" src="/home/hairweaving1.png" alt="Hair Weaving" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Hair Weaving</h3>
											<p>In contrast, hair weaving, like bonding, is a non-surgical process.It involves integrating human hair extensions to create a stunning look.</p>
											<p>The main distinction with hair weaving lies in its technique—additional hair is seamlessly attached to your natural hair at the scalp.</p>
											<Link to="/hair-weaving-for-women.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
											Know More
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="massage">
							<div className="row align-items-center">
								<div className="col-md-12 px-4">
									<div className="services__area-image mb-4">
										<img className="img__full" src="/home/hair-clipping.jpg" alt="Hair Clipping" />
									</div>									
								</div>
								<div className="col-md-12 px-4">
									<div className="services__area-right ml-40 lg-ml-0">
										<div className="services__area-right-title">
											<h3>Hair Clipping</h3>
											<p>The versatility of this approach has led to its enormous appeal.. With clip-in extensions, you can effortlessly add or remove extra hair to achieve thicker, longer, or more voluminous locks, tailored perfectly to your preference.</p>
											<p>Very easy to attach, very convenient to remove while going to bed.</p>
											<Link to="/tape-in-and-clip-in-hair-extension.php" className="btn btn-default black" style={{ marginLeft: 'auto' }}>
											Know More
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
        </div>
      </Container>
	  </Fade>
    </SectionWrapper>
  );
};

export default WhyRestoration;
