import React from "react";
import SectionWrapper, { Container } from "./whychoose.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Fade from "react-reveal/Fade";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const WhyChoose = () => {
  const allWhyChoose = [
    {
      id: "1",
      title: "Non-Surgical Hair Replacement",
      desc: "It's a totally non-invasive procedure, not involving any surgery whatsoever, most importantly it's virtually undetectable.",
    },
    {
      id: "2",
      title: "Ready to Wear",
      desc: "You can literally walk out from the center with a full head of hair about an hour after with the look you desire.",
    },
    {
      id: "3",
      title: "Affordable Price",
      desc: "The important advantage that you gain when going for non-surgical hair replacement systems is it is easy and cheaper than all other processes.",
    },
    // {
    //   id: "4",
    //   title: "30 Days Money Back Guarantee",
    //   desc: "If there are any issues with your product, you can raise a replacement orexchange request within 30 days of purchase of the product.",
    // },
    {
      id: "4",
      title: "Maintenance",
      desc: "Non-surgical hair replacement systems are super easy to maintain, Wash & blow dry hair every day. No tangling, no slippage at all.",
    },
    {
      id: "5",
      title: "Workout Friendly",
      desc: "You can exercise, swim, sweat, workout every day & bond will never be compromised!And most essential you will look beautiful too while doing it!!",
    },
    {
      id: "6",
      title: "Natural",
      desc: "Non-surgical hair replacement systems are 100% natural method that uses no chemicals or tools. No wasted materials used either.",
    },
    {
      id: "7",
      title: "Easy to Remove",
      desc: "One benefit of non-surgical hair systems is the safety of a reversible procedure.surgical hair systems is the safety of having a process that is reversible.You may easily get your hair replacement system taken out if you decide you no longer want to wear it; it is not permanent.",
    },
    {
      id: "10",
      title: "Customer Support",
      desc: "It's obvious that sometimes you need a little help from us. Don’t worry... We’re always here for you.If you have any problems, our customer service desk is available to help.",
    },
  ];

  return (
    <SectionWrapper id="whyChoose">
      <div className="myContainer">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="firstpara">
              <div className="headB">
                <Fade left>
                  <p>Why Pick Us</p>
                  <h3 className="title">Radiance Hair Studio?</h3>
                </Fade>
              </div>
              <Fade right>
                <p className="who-we-are-sec pt-20">
                Making each customer look their best is our top priority at Radiance Hair Studio. Our clients receive high-quality, one-on-one attention. Before every procedure, every client receives a thorough consultation to determine the best course of action for them. 
                </p>
              </Fade>
            </div>
          </div>
          <div className="col-md-12 col-sm-12">
            <div className="row">
              <Fade bottom>
                <div className="col-md-12">
                  <div
                    className="reviewFold"
                    id="whyChooseSlider"
                    style={{ display: "block" }}
                  >
                    
                    <Swiper
                      spaceBetween={20} // Adjust as needed
                      slidesPerView={2} // Display 4 slides per view
                      autoplay={{ delay: 3000 }}
                      navigation={{
                        prevEl: ".swiper-button-prevW",
                        nextEl: ".swiper-button-nextW",
                      }}
                      pagination={{ clickable: true }}
                      breakpoints={{
                        300: {
                          // width: 768,
                          slidesPerView: 1,
                        },
                        768: {
                          // width: 768,
                          slidesPerView: 4,
                        },
                      }}
                    >
                      {allWhyChoose.map((choose, index) => (
                        <SwiperSlide key={index}>
                          <div className="whyCard">
                            <p className="numberIcon">
                              <span>{(choose.id == 10)? choose.id : '0' + choose.id}</span>
                            </p>
                            <h4>{choose.title}</h4>
                            <p className="desc">{choose.desc}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="flex gReviewsIcos">
                    {/* Custom navigation buttons */}
                    <div className="swiper-button-prevW">
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="swiper-button-nextW">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default WhyChoose;
