import React from 'react';
import SectionWrapper, {
    Container
} from './hairPatch.style';

import Fade from 'react-reveal/Fade';

const HairPatch = () => {

    return (
        <SectionWrapper id="counters">
            <Container className='myContainer flexDirecColumn px-3'>
                <Fade bottom><h2 style={{ color: '#000', marginBottom: '20px', textAlign: 'center' }}>Hair Patch in Delhi for Mens and Women</h2></Fade>
                <Fade bottom>
                    <div className="achivment">
                     <p>Hair patches have become a widely preferred and highly effective solution for individuals facing hair loss in Delhi. This non-surgical approach provides an instant transformation, offering a natural-looking, fuller head of hair without resorting to invasive procedures like hair transplants. Crafted from premium-quality synthetic or natural hair, hair patches are tailored to match each individual's unique hair texture, color, and density. </p>
                    <p>Radiance Hair Clinic stands out as a top provider of high-quality hair patches in Delhi, recognized for its exceptional products and superior customer care. Numerous clients have regained their confidence through the clinics personalized hair patch solutions. </p>
                    </div>
                </Fade>
            </Container>
        </SectionWrapper>
    );
};

export default HairPatch;
