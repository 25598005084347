import React from "react";
import Fade from "react-reveal/Fade";
import Heading from "common/components/Heading";
import BlogPost from "common/components/BlogPost";
import Container from "common/components/UI/Container";
import { SectionHeader } from "../global.style";
import SectionWrapper, { FeatureWrapper } from "./feature.style";

import sleepInIt from "../../../common/assets/image/interior/icons/feature1.png";
import sweemInIt from "../../../common/assets/image/interior/icons/feature2.png";
import showerInIt from "../../../common/assets/image/interior/icons/feature3.png";
import exerciseInIt from "../../../common/assets//image/interior/icons/feature4.png";

const Feature = () => {
  const allFeatures = [
    {
      id: 1,
      icon: sleepInIt,
      title: "Sleep in it",
      description:
        "With these hair patches, there is no need to remove them even while sleeping! Using a silk or satin pillow will prolong the life of your hairpiece.",
    },
    {
      id: 2,
      icon: sweemInIt,
      title: "Swim in it",
      description: "Absolutely! You can even wear your new hairstyle while swimming.",
    },
    {
      id: 3,
      icon: showerInIt,
      title: "Shower in it",
      description:
        "No need to spend time removing it before taking a bath, just like your own hair.",
    },
    {
      id: 4,
      icon: exerciseInIt,
      title: "Exercise in it",
      description:
        "Your hairpiece will stay in place and unnoticeable whether you exercise, lift weights, or run. ",
    },
  ];

  // console.log(allFeatures);

  return (
    <SectionWrapper id="whyRadiance">
      <Container className="myContainer clasno bg-image">
        <div className="row">
          <div className="col-md-6" style={{display: 'flex', alignItems: 'center'}}>
            <Fade bottom>
              <SectionHeader className="" style={{textAlign: 'left'}}>
                <Heading as="h5" content="Product Features" />
                <Heading content="Up to 4 weeks continuous wear" className="pb-3"/>
                <p>At Radiance, we prioritize customer satisfaction and encourage creativity, helping you express yourself boldly through your hairstyle.</p>
                <p><b>Feel free to express yourself through your looks!</b></p>
              </SectionHeader>
            </Fade>
          </div>
          <div className="col-md-6">
            <Fade bottom delay={30}>
              <div className="parent">
                {allFeatures.map((item) => (
                  <BlogPost
                    key={`feature_key${item.id}`}
                    thumbUrl={item.icon}
                    title={item.title}
                    className={`div${item.id}`}
                    excerpt={item.description}
                  />
                ))}
              </div>
            </Fade>
          </div>
        </div>
      </Container>
    </SectionWrapper>
  );
};

export default Feature;
